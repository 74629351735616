import { OnlinePaymentsAccountBalance } from './onlinePaymentsAccountBalance';
import { OnlinePaymentsPayoutSettings } from './onlinePaymentsPayoutSettings';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlinePaymentsStatus =
  | {
      readonly type: 'Enabled';
      readonly balance: OnlinePaymentsAccountBalance;
      readonly payout: OnlinePaymentsPayoutSettings;
    }
  | {
      readonly type: 'Disabled';
    };

const JSON: t.Type<OnlinePaymentsStatus, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Enabled'),
        balance: OnlinePaymentsAccountBalance.JSON,
        payout: OnlinePaymentsPayoutSettings.JSON,
      },
      'Enabled',
    ),
    t.type(
      {
        type: t.literal('Disabled'),
      },
      'Disabled',
    ),
  ],
  'OnlinePaymentsAccountStatus',
);

export const OnlinePaymentsStatus = {
  JSON,
};
