import { AppointmentPaymentsOption } from './appointmentPaymentsOption';
import { RequireAdvancePaymentOption } from './requireAdvancePaymentOption';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageOnlinePaymentsSettings = {
  /**
   * This field is optional for backwards compatibility, until migration is executed
   * None means "On"
   */
  readonly appointmentPayments: Option<AppointmentPaymentsOption>;
  readonly requireAdvancePayment: RequireAdvancePaymentOption;
  readonly advancePaymentTos: Option<string>;
};

const JSON: t.Type<PageOnlinePaymentsSettings, JSONable> = t.type(
  {
    appointmentPayments: Option.json(AppointmentPaymentsOption.JSON),
    requireAdvancePayment: RequireAdvancePaymentOption.JSON,
    advancePaymentTos: Option.json(t.string),
  },
  'PageOnlinePaymentsSettings',
);

const DEFAULT: PageOnlinePaymentsSettings = {
  appointmentPayments: AppointmentPaymentsOption.DEFAULT,
  requireAdvancePayment: RequireAdvancePaymentOption.DEFAULT,
  advancePaymentTos: undefined,
};

export const PageOnlinePaymentsSettings = {
  JSON,
  DEFAULT,
};
