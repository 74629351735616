import { marketplaceBootHttpClient } from './boot';
import { marketplaceFavoritesHttpClient } from './favorites';
import { marketplaceLinkingHttpClient } from './linking';
import { marketplaceMembershipsHttpClient } from './memberships';
import { MeroMarketplaceApi } from './meroMarketplaceApi';
import { HttpClient } from '@mero/shared-sdk';

export const meroMarketplaceHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MeroMarketplaceApi => {
  const boot = marketplaceBootHttpClient(env);
  const linking = marketplaceLinkingHttpClient(env);
  const favorites = marketplaceFavoritesHttpClient(env);
  const memberships = marketplaceMembershipsHttpClient(env);

  return {
    boot,
    linking,
    favorites,
    memberships,
  };
};
