import { Firstname, OptionalLastname } from '../common';
import { WorkerId } from '../workers/workerId';
import { PageGiftCardSettings } from './page-gift-card-settings';
import { PageId } from './page-id';
import { PortionPercentNumber, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets/profile-image';
import { ResponsiveImagePreview } from '@mero/shared-sdk/dist/assets/responsive-image';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export const PageSearchWorker = t.type(
  {
    _id: WorkerId.JSON,
    user: t.type({
      firstname: Firstname,
      lastname: OptionalLastname,
    }),
    profilePhoto: ProfileImage,
  },
  'PageSearchWorker',
);

const Hightlight = t.type({
  value: t.string,
  matchLevel: t.string,
  matchedWords: t.array(t.string),
});

export const PageSearch = t.type(
  {
    _id: PageId,
    slug: t.string,
    name: t.string,
    profilePhoto: ProfileImage,
    categoryIds: t.array(t.string),
    images: t.array(ResponsiveImagePreview),
    workers: t.array(PageSearchWorker),
    location: t.type({
      type: t.string,
      coordinates: t.array(t.number),
    }),
    city: optionull(t.string),
    address: optionull(t.string),
    feedback: t.type({
      score: t.number,
      total: t.number,
    }),
    hasOnlinePaymentsEnabled: optionull(t.boolean),
    giftCardSettings: PageGiftCardSettings,
    _highlightResult: optionull(
      t.type({
        name: Hightlight,
        workers: t.union([
          t.undefined,
          t.array(
            t.type({
              user: t.type({
                firstname: Hightlight,
                lastname: Hightlight,
              }),
            }),
          ),
        ]),
      }),
    ),
    isPromoted: optionull(t.boolean),
    isFavorite: optionull(t.boolean),
    maxDiscountPercent: optionull(PortionPercentNumber.JSON),
  },
  'PageSearch',
);

export type PageSearch = t.TypeOf<typeof PageSearch>;

export const PageSearchWithHasPromoPrice = t.intersection(
  [
    PageSearch,
    t.type(
      {
        hasPromoPrice: t.boolean,
        _hitMeta: optionull(tt.Json),
      },
      '!',
    ),
  ],
  'PageSearchWithHasPromoPrice',
);

export type PageSearchWithHasPromoPrice = t.TypeOf<typeof PageSearchWithHasPromoPrice>;
